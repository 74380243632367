var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as Sentry from '@sentry/browser';
var SCREEN_SIZE;
(function (SCREEN_SIZE) {
    SCREEN_SIZE["PHONE"] = "PHONE";
    SCREEN_SIZE["TABLET"] = "TABLET";
    SCREEN_SIZE["DESKTOP"] = "DESKTOP";
})(SCREEN_SIZE || (SCREEN_SIZE = {}));
var OS;
(function (OS) {
    OS["MAC_OS"] = "MAC_OS";
    OS["IOS"] = "IOS";
    OS["WINDOWS"] = "WINDOWS";
    OS["ANDROID"] = "ANDROID";
    OS["LINUX"] = "LINUX";
})(OS || (OS = {}));
var BROWSER;
(function (BROWSER) {
    BROWSER["OPERA"] = "OPERA";
    BROWSER["FIREFOX"] = "FIREFOX";
    BROWSER["SAFARI"] = "SAFARI";
    BROWSER["IE"] = "IE";
    BROWSER["EDGE"] = "EDGE";
    BROWSER["CHROME"] = "CHROME";
})(BROWSER || (BROWSER = {}));
var TestType;
(function (TestType) {
    TestType["AB"] = "AB";
    TestType["SPLIT"] = "SPLIT";
})(TestType || (TestType = {}));
var ConversionType;
(function (ConversionType) {
    ConversionType["LEGACY_CLICK_SUBMIT"] = "LEGACY_CLICK_SUBMIT";
    ConversionType["CLICK"] = "CLICK";
    ConversionType["SUBMIT"] = "SUBMIT";
    ConversionType["PAGE_VIEW"] = "PAGE_VIEW";
    ConversionType["SCROLL_DEPTH"] = "SCROLL_DEPTH";
    ConversionType["TIME_ON_PAGE"] = "TIME_ON_PAGE";
    ConversionType["PROGRAMAMTIC"] = "PROGRAMAMTIC";
})(ConversionType || (ConversionType = {}));
var ScrollDepthUnit;
(function (ScrollDepthUnit) {
    ScrollDepthUnit["PIXELS"] = "PIXELS";
    ScrollDepthUnit["PERCENTAGE"] = "PERCENTAGE";
})(ScrollDepthUnit || (ScrollDepthUnit = {}));
Sentry.setTag('url', window.location.href);
var optibaseSentryClient = new Sentry.BrowserClient({
    dsn: 'https://3266931c85fced597df7d2c3891c380a@o4506872303845376.ingest.us.sentry.io/4506882884370432',
    transport: Sentry.makeFetchTransport,
    stackParser: Sentry.defaultStackParser,
    integrations: [],
    environment: process.env.NEXT_PUBLIC_VERCEL_ENV,
});
var OptibaseUserError = /** @class */ (function (_super) {
    __extends(OptibaseUserError, _super);
    function OptibaseUserError(message) {
        var _this = _super.call(this, message) || this; // 'Error' breaks prototype chain here
        Object.setPrototypeOf(_this, OptibaseUserError.prototype); // Restore prototype chain
        return _this;
    }
    return OptibaseUserError;
}(Error));
// Encapsulated in a function to create a localized scope
function Optibase() {
    if (window.optibaseScriptLoaded) {
        throw new OptibaseUserError('[Optibase] Detected duplicated Optibase Script.');
    }
    function log(scope) {
        var messages = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            messages[_i - 1] = arguments[_i];
        }
        if (debugMode) {
            console.log.apply(console, __spreadArray(["[Optibase ".concat(scope, "]:")], messages, false));
        }
    }
    // Validates the execution context of the current script element within the document.
    function validateExecution() {
        var _a;
        var currentScript = document.currentScript;
        if (!currentScript) {
            throw new Error('[Optibase] Javascript not running withing a script.');
        }
        var publicApiKey = currentScript.getAttribute('public-api-key');
        if (!publicApiKey) {
            throw new OptibaseUserError('[Optibase] Error: Missing public-api-key attribute in script tag.');
        }
        var currentScriptSrc = currentScript.getAttribute('src');
        if (!currentScriptSrc) {
            throw new Error('[Optibase] Error: Missing src attribute in script tag.');
        }
        var currentScriptSrcUrl = null;
        try {
            currentScriptSrcUrl = new URL(currentScriptSrc);
        }
        catch (_b) { }
        if (!currentScriptSrcUrl) {
            throw new OptibaseUserError('[Optibase] Error: Invalid src attribute in script tag. It should be a valid URL. Current is url: ' +
                currentScriptSrc);
        }
        var baseApiUrl = "".concat(currentScriptSrcUrl.protocol, "//").concat(currentScriptSrcUrl.hostname).concat(currentScriptSrcUrl.port ? ':' + currentScriptSrcUrl.port : '');
        var canHideBody = true;
        if (currentScript.async) {
            console.error('[Optibase] Script is running as async. Remove async from the script.');
            canHideBody = false;
        }
        if (currentScript.defer) {
            console.error('[Optibase] Script is running as defer. Remove defer from the script.');
            canHideBody = false;
        }
        if (((_a = currentScript.parentNode) === null || _a === void 0 ? void 0 : _a.nodeName) !== 'HEAD') {
            console.error('[Optibase] Script is not in the head element. Move the script to the <head> element.');
            canHideBody = false;
        }
        log('validateExecution', "Found publicApiKey: ".concat(publicApiKey, ", baseApiUrl: ").concat(baseApiUrl, ", and canHideBody: ").concat(canHideBody, "."));
        return {
            publicApiKey: publicApiKey,
            baseApiUrl: baseApiUrl,
            canHideBody: canHideBody,
        };
    }
    function getConfig() {
        // Assume `window.location.search` contains the query string of the current URL
        var params = new URLSearchParams(window.location.search);
        // Debug Mode Config
        debugMode = Boolean(localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem('optibaseDebugMode'));
        if (params.has('optibaseEnableDebugMode')) {
            log('getConfig', 'Params has optibaseEnableDebugMode; enabling debug mode.');
            localStorage === null || localStorage === void 0 ? void 0 : localStorage.setItem('optibaseDebugMode', 'true');
            debugMode = true;
        }
        if (params.has('optibaseDisableDebugMode')) {
            log('getConfig', 'Params has optibaseDisableDebugMode; disabling debug mode.');
            localStorage === null || localStorage === void 0 ? void 0 : localStorage.removeItem('optibaseDebugMode');
            debugMode = false;
        }
        var previewVariant = null;
        var forcedVariants = [];
        // Preview Variants
        if (params.has('optibasePreviewVariant')) {
            var optibasePreviewVariant = params.get('optibasePreviewVariant');
            log('getConfig', 'Params has optibasePreviewVariant:', optibasePreviewVariant);
            var _a = optibasePreviewVariant.split(':'), userFriendlyTestId = _a[0], userFriendlyVariantId = _a[1];
            if (userFriendlyTestId && userFriendlyVariantId) {
                previewVariant = {
                    userFriendlyTestId: userFriendlyTestId,
                    userFriendlyVariantId: userFriendlyVariantId,
                };
            }
            else {
                console.error('[Optibase] Error: Invalid optibasePreviewVariant format. It should be "userFriendlyTestId:userFriendlyVariantId".');
            }
        }
        // Forced Variants
        if (params.has('optibaseVariants')) {
            var optibaseForcedVariantsSearchParam = params.get('optibaseVariants');
            log('getConfig', 'Params has optibaseVariants:', optibaseForcedVariantsSearchParam);
            var optibaseForcedVariants = optibaseForcedVariantsSearchParam
                .split(';')
                .map(function (optibaseForcedVariantSearchParam) {
                return optibaseForcedVariantSearchParam.split(':');
            });
            optibaseForcedVariants.forEach(function (_a) {
                var userFriendlyTestId = _a[0], userFriendlyVariantId = _a[1];
                if (userFriendlyTestId && userFriendlyVariantId) {
                    forcedVariants.push({
                        userFriendlyTestId: userFriendlyTestId,
                        userFriendlyVariantId: userFriendlyVariantId,
                    });
                }
                else {
                    console.error('[Optibase] Error: Invalid optibaseVariants format. It should be "userFriendlyTestId:userFriendlyVariantId".');
                }
            });
        }
        // Script Disabled Config
        var scriptDisabled = Boolean(localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem('optibaseScriptDisabled'));
        if (params.has('optibaseDisableScript')) {
            log('getConfig', 'Params has optibaseDisableScript; disabling script.');
            localStorage === null || localStorage === void 0 ? void 0 : localStorage.setItem('optibaseScriptDisabled', 'true');
            scriptDisabled = true;
        }
        else if (params.has('optibaseEnableScript')) {
            log('getConfig', 'Params has optibaseEnableScript; enabling script.');
            localStorage === null || localStorage === void 0 ? void 0 : localStorage.removeItem('optibaseScriptDisabled');
            scriptDisabled = false;
        }
        else if (previewVariant) {
            log('getConfig', 'Disabling script to show the preview variant.');
            scriptDisabled = true;
        }
        log('getConfig', "Found scriptDisabled: ".concat(scriptDisabled, ", debugMode: ").concat(debugMode, ", previewVariant:"), previewVariant, 'and forcedVariants:', forcedVariants);
        return { scriptDisabled: scriptDisabled, previewVariant: previewVariant, forcedVariants: forcedVariants };
    }
    function getCurrentBrowser() {
        var userAgent = window.navigator.userAgent.toLowerCase();
        var browser = userAgent.indexOf('edge') > -1
            ? BROWSER.EDGE
            : userAgent.indexOf('edg') > -1
                ? BROWSER.EDGE
                : // @ts-ignore
                    userAgent.indexOf('opr') > -1 && !!window.opr
                        ? BROWSER.OPERA
                        : // @ts-ignore
                            userAgent.indexOf('chrome') > -1 && !!window.chrome
                                ? BROWSER.CHROME
                                : userAgent.indexOf('trident') > -1
                                    ? BROWSER.IE
                                    : userAgent.indexOf('firefox') > -1
                                        ? BROWSER.FIREFOX
                                        : userAgent.indexOf('safari') > -1
                                            ? BROWSER.SAFARI
                                            : undefined;
        log('metadata', "Current Browser: ".concat(browser, "."));
        return browser;
    }
    function getCurrentOS() {
        var userAgent = window.navigator.userAgent;
        var platform = window.navigator.platform;
        var currentOS = undefined;
        if (/Macintosh|MacIntel|MacPPC|Mac68K|Darwin/.test(platform)) {
            currentOS = OS.MAC_OS;
        }
        else if (/iPhone|iPad|iPod/.test(platform)) {
            currentOS = OS.IOS;
        }
        else if (/Win32|Win64|Windows|WinCE/.test(platform)) {
            currentOS = OS.WINDOWS;
        }
        else if (/Android/.test(userAgent)) {
            currentOS = OS.ANDROID;
        }
        else if (/Linux/.test(platform)) {
            currentOS = OS.LINUX;
        }
        log('metadata', "Current OS: ".concat(currentOS, "."));
        return currentOS;
    }
    function getCurrentScreenSize() {
        var currentScreenSize = window.innerWidth <= 767
            ? SCREEN_SIZE.PHONE
            : window.innerWidth <= 990
                ? SCREEN_SIZE.TABLET
                : SCREEN_SIZE.DESKTOP;
        log('metadata', "Current Screen Size: ".concat(currentScreenSize, "."));
        return currentScreenSize;
    }
    function getCurrentTopReferrer() {
        var _a, _b;
        var currentTopReferrer;
        try {
            // Attempt to access the top frame's referrer
            currentTopReferrer = (_b = (_a = window === null || window === void 0 ? void 0 : window.frames) === null || _a === void 0 ? void 0 : _a.top) === null || _b === void 0 ? void 0 : _b.document.referrer;
        }
        catch (_c) {
            // If an error occurs (e.g., due to cross-origin Restrictions), handle it gracefully
            currentTopReferrer = undefined;
        }
        log('metadata', "Current Top Refferer: ".concat(currentTopReferrer, "."));
        return currentTopReferrer;
    }
    function getCurrentOriginUrl() {
        var currentOriginUrl = window.location.origin + window.location.pathname;
        log('metadata', "Current Origin Url: ".concat(currentOriginUrl, "."));
        return currentOriginUrl;
    }
    function validateAndCleanUrl(url) {
        try {
            var urlObject = new URL(url.startsWith('http') ? url : "https://".concat(url));
            return urlObject.origin + urlObject.pathname;
        }
        catch (_) {
            return null;
        }
    }
    function validateAndExtractPathFromUrl(url) {
        try {
            var urlObject = new URL(url.startsWith('http') ? url : "https://".concat(url));
            return urlObject.pathname;
        }
        catch (_) {
            return null;
        }
    }
    var hideBodyStyle = document.createElement('style');
    function hideBody() {
        log('hideBody', 'Hiding body.');
        // Set the CSS text for making the body invisible with !important to ensure it takes precedence
        hideBodyStyle.innerHTML = 'body { visibility: hidden !important; }';
        // Append the <style> element to the <head> of the document
        document.head.appendChild(hideBodyStyle);
    }
    function showBody() {
        var _a;
        log('showBody', 'Showing body.');
        (_a = hideBodyStyle.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(hideBodyStyle);
    }
    function getInitialize(testsOnPage, conversionsOnPage) {
        return __awaiter(this, void 0, void 0, function () {
            var activeResponse, activeResponseJson, initializeBody, response, responseJson_1, responseText, responseJson;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        log('getInitialize', "Fetching active for publicApiKey: ".concat(publicApiKey, "."));
                        return [4 /*yield*/, fetch(baseApiUrl + "/api/script/active?publicApiKey=".concat(publicApiKey))];
                    case 1:
                        activeResponse = _a.sent();
                        if (!activeResponse.ok) {
                            throw new OptibaseUserError("[Optibase] Error occurred while fetching active.");
                        }
                        return [4 /*yield*/, activeResponse.json()];
                    case 2:
                        activeResponseJson = _a.sent();
                        log('getInitialize', "Active response:", activeResponseJson);
                        if (!activeResponseJson.active) {
                            return [2 /*return*/, {
                                    userNotLoggedIn30Days: true,
                                    randomSeed: Math.floor(Math.random() * 1000000000),
                                }];
                        }
                        initializeBody = {
                            publicApiKey: publicApiKey,
                            testedUserId: testedUserId,
                            entryUrl: currentOriginUrl,
                            screenSize: currentScreenSize,
                            browser: currentBrowser,
                            OS: currentOS,
                            topReferrer: currentTopReferrer,
                            testsOnPage: testsOnPage,
                            conversionsOnPage: conversionsOnPage,
                        };
                        log('getInitialize', "Calling with body:", initializeBody);
                        return [4 /*yield*/, fetch(baseApiUrl + "/api/script/initialize", {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(initializeBody),
                                credentials: 'include',
                            }).catch(function () { })];
                    case 3:
                        response = _a.sent();
                        if (!response) {
                            // Don't surface the error to Sentry because
                            // it's usually a network error
                            throw new OptibaseUserError("[Optibase] Error occurred while fetching initialize.");
                        }
                        if (!!response.ok) return [3 /*break*/, 6];
                        return [4 /*yield*/, response.json().catch(function () { })];
                    case 4:
                        responseJson_1 = _a.sent();
                        return [4 /*yield*/, response.text().catch(function () { })];
                    case 5:
                        responseText = _a.sent();
                        if (response.status === 400 && !responseJson_1.clientError) {
                            // This means it was a user error, like running on
                            // a non valid domain
                            throw new OptibaseUserError('[Optibase] Response not ok when calling getInitialize: ' +
                                (responseJson_1 === null || responseJson_1 === void 0 ? void 0 : responseJson_1.message) ||
                                responseText ||
                                'Unknown error');
                        }
                        else if (response.status === 500) {
                            // This means it was an error on the server, don't surface to Sentry
                            throw new OptibaseUserError('[Optibase] Response not ok when calling getInitialize: ' +
                                (responseJson_1 === null || responseJson_1 === void 0 ? void 0 : responseJson_1.message) ||
                                responseText ||
                                'Internal Server Error');
                        }
                        else {
                            throw new Error('[Optibase] Response not ok when calling getInitialize: ' +
                                (responseJson_1 === null || responseJson_1 === void 0 ? void 0 : responseJson_1.message) ||
                                responseText ||
                                'Unknown error');
                        }
                        _a.label = 6;
                    case 6: return [4 /*yield*/, response.json()];
                    case 7:
                        responseJson = _a.sent();
                        log('getInitialize', "Response:", responseJson);
                        return [2 /*return*/, responseJson];
                }
            });
        });
    }
    var randomNumberGeneratorSeed = 0;
    function generateSeededRandomNumber() {
        // Constants for the LCG algorithm
        var a = 1664525;
        var c = 1013904223;
        var m = Math.pow(2, 32);
        // Update the seed and return a pseudo-random value
        randomNumberGeneratorSeed = (a * randomNumberGeneratorSeed + c) % m;
        return randomNumberGeneratorSeed / m;
    }
    function handleCachedInitializeResponse(cachedInitializeResponseString) {
        var _a, _b;
        // In case there is a cachedInitializeResponse check:
        // Split Test: If, based on the cachedInitializeResponse,
        // there will be a redirect, wait for the live server Initialize
        // Response before showing the body.
        // A/B Test: Handle all the A/B Test like normal
        if (cachedInitializeResponseString) {
            var cachedInitializeResponse = JSON.parse(cachedInitializeResponseString);
            log('handleCachedInitializeResponse', "Detected cached getInitialize response:", cachedInitializeResponse);
            if (!cachedInitializeResponse.randomSeed) {
                localStorage === null || localStorage === void 0 ? void 0 : localStorage.removeItem('optibaseCachedInitializeResponse');
                throw new Error('[Optibase] Random seed not returned.');
            }
            randomNumberGeneratorSeed = cachedInitializeResponse.randomSeed;
            var willRedirect = handleSplitTests(((_a = cachedInitializeResponse.tests) === null || _a === void 0 ? void 0 : _a.filter(function (test) { return test.eligible && test.test.type === TestType.SPLIT; })) || [], true, previewVariant, forcedVariants);
            if (!willRedirect) {
                handleABTests(((_b = cachedInitializeResponse.tests) === null || _b === void 0 ? void 0 : _b.filter(function (test) { return test.test.type === TestType.AB; })) || [], true, previewVariant, forcedVariants);
                showBody();
            }
        }
        else {
            log('handleCachedInitializeResponse', "No cached getInitialize response");
        }
    }
    function handleInitializeResponse(initializeResponse) {
        return __awaiter(this, void 0, void 0, function () {
            var willRedirect, timeToSetup;
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            return __generator(this, function (_k) {
                switch (_k.label) {
                    case 0:
                        if (initializeResponse.monthlyTestedUsersLimitReached) {
                            window.monthlyTestedUsersLimitReached = true;
                            console.error('[Optibase] The Monthly Tested Users limit has been reached.');
                        }
                        if (initializeResponse.noTestsOrConversionsOnPage) {
                            window.noTestsOrConversionsOnPage = true;
                            log('handleInitializeResponse', "No tests or conversions found on the page.");
                        }
                        if (initializeResponse.userNotLoggedIn30Days) {
                            window.userNotLoggedIn30Days = true;
                            console.error('handleInitializeResponse', 'The user has not logged in in the last 30 days, please log in again to start running tests. It might take up to 2h for the script to be active again.');
                        }
                        if (!initializeResponse.randomSeed) {
                            throw new Error('[Optibase] Random seed not returned.');
                        }
                        localStorage === null || localStorage === void 0 ? void 0 : localStorage.setItem('optibaseCachedInitializeResponse', JSON.stringify(initializeResponse));
                        randomNumberGeneratorSeed = initializeResponse.randomSeed;
                        if (initializeResponse.testedUserId) {
                            testedUserId = initializeResponse.testedUserId;
                            window.optibaseTestedUserId = testedUserId;
                            (_a = window.localStorage) === null || _a === void 0 ? void 0 : _a.setItem('optibaseTestedUserId', initializeResponse.testedUserId);
                        }
                        if (initializeResponse.testedUserExternalId) {
                            testedUserExternalId = initializeResponse.testedUserExternalId;
                            window.optibaseTestedUserExternalId =
                                initializeResponse.testedUserExternalId;
                            (_b = window.localStorage) === null || _b === void 0 ? void 0 : _b.setItem('optibaseTestedUserExternalId', initializeResponse.testedUserExternalId);
                        }
                        willRedirect = handleSplitTests(((_c = initializeResponse.tests) === null || _c === void 0 ? void 0 : _c.filter(function (test) { return test.eligible && test.test.type === TestType.SPLIT; })) || [], false, previewVariant, forcedVariants);
                        if (willRedirect) {
                            return [2 /*return*/];
                        }
                        handleABTests(((_d = initializeResponse.tests) === null || _d === void 0 ? void 0 : _d.filter(function (test) { return test.test.type === TestType.AB; })) || [], false, previewVariant, forcedVariants);
                        window.optibaseInitialized = true;
                        showBody();
                        timeToSetup = new Date().getTime() - startDate.getTime();
                        if (!!scriptDisabled) return [3 /*break*/, 2];
                        handleClickConversions(((_e = initializeResponse.conversions) === null || _e === void 0 ? void 0 : _e.filter(function (conversion) {
                            return conversion.type === ConversionType.CLICK ||
                                conversion.type === ConversionType.LEGACY_CLICK_SUBMIT;
                        })) || []);
                        handleSubmitConversions(((_f = initializeResponse.conversions) === null || _f === void 0 ? void 0 : _f.filter(function (conversion) {
                            return conversion.type === ConversionType.SUBMIT ||
                                conversion.type === ConversionType.LEGACY_CLICK_SUBMIT;
                        })) || []);
                        handleScrollDepthConversions(((_g = initializeResponse.conversions) === null || _g === void 0 ? void 0 : _g.filter(function (conversion) { return conversion.type === ConversionType.SCROLL_DEPTH; })) || []);
                        handleTimeOnPageConversions(((_h = initializeResponse.conversions) === null || _h === void 0 ? void 0 : _h.filter(function (conversion) { return conversion.type === ConversionType.TIME_ON_PAGE; })) || []);
                        // Send a view event for all selected variants
                        return [4 /*yield*/, sendView(timeToSetup)];
                    case 1:
                        // Send a view event for all selected variants
                        _k.sent();
                        handlePageViewConversion(((_j = initializeResponse.conversions) === null || _j === void 0 ? void 0 : _j.filter(function (conversion) { return conversion.type === ConversionType.PAGE_VIEW; })) || []);
                        _k.label = 2;
                    case 2:
                        log('handleInitializeResponse', "Finished setting up Optibase. Time to setup: ".concat(timeToSetup, "ms."));
                        return [2 /*return*/];
                }
            });
        });
    }
    function doesUrlMatchCurrentOrigin(url, sameOrigin) {
        if ((sameOrigin && url === cleanCurrentOriginPath) ||
            (!sameOrigin && url === cleanCurrentOriginUrl)) {
            return true;
        }
        else {
            false;
        }
    }
    function selectRandomWeightedVariant(variants, randomNumber) {
        // Calculate the total weight
        var totalWeight = variants.reduce(function (acc, variant) { return acc + (variant.trafficSplitWeight || 0); }, 0);
        // Scale the random number with the total weight
        var scaledRandom = randomNumber * totalWeight;
        // Iterate through the variants to find the selected one based on the scaled random number
        for (var _i = 0, variants_1 = variants; _i < variants_1.length; _i++) {
            var variant = variants_1[_i];
            // Subtract the variant's weight from the scaled random number
            scaledRandom -= variant.trafficSplitWeight || 0;
            // If the scaled random number is less than or equal to zero, we've found our variant
            if (scaledRandom <= 0) {
                return variant;
            }
        }
        // In case all variants are passed but no selection is made (should not happen in theory),
        // return the last variant as a fallback
        return variants[variants.length - 1];
    }
    // Manages split tests by determining if the current page matches a split test variant.
    // If a match is found and it's not the main variant, the user is redirected to the variant page.
    // This function also handles storing and retrieving previously shown variants from localStorage?.
    function handleSplitTests(splitTests, fromCached, previewVariant, forcedVariants) {
        var logScope = fromCached ? 'handleSplitTests cached' : 'handleSplitTests';
        log(logScope, "Handling Split Tests splitTests:", splitTests, 'fromCached', fromCached);
        var splitTestWeightedRandomVariant = null;
        var previewOrForcedOrWinnerVariant = false;
        var dontPushVariant = false;
        var splitTestToRun = null;
        var _loop_1 = function (splitTest) {
            log(logScope, "Checking split test:", splitTest);
            var entryVariant = splitTest.test.variants.find(function (variant) { return variant.main; });
            var entryUrl = entryVariant.url;
            var sameOrigin = entryVariant.sameOrigin;
            var randomNumber = generateSeededRandomNumber();
            log(logScope, "Checking if URL matches the current origin. entryUrl: ".concat(entryUrl, ", sameOrigin: ").concat(sameOrigin, "."));
            if (doesUrlMatchCurrentOrigin(entryUrl, sameOrigin)) {
                log(logScope, "URL matches the current origin. Checking for a variant to run.");
                splitTestToRun = splitTest;
                if ((previewVariant === null || previewVariant === void 0 ? void 0 : previewVariant.userFriendlyTestId) === splitTest.test.userFriendlyId) {
                    var previewVariantIndex = splitTest.test.variants.findIndex(function (variant) {
                        return variant.userFriendlyId === (previewVariant === null || previewVariant === void 0 ? void 0 : previewVariant.userFriendlyVariantId);
                    });
                    if (previewVariantIndex !== -1) {
                        splitTestWeightedRandomVariant =
                            splitTest.test.variants[previewVariantIndex];
                        previewOrForcedOrWinnerVariant = true;
                        dontPushVariant = true;
                        log(logScope, "Selected preview variant:", splitTestWeightedRandomVariant);
                    }
                }
                else if (forcedVariants.length) {
                    var forcedVariantIndex = splitTest.test.variants.findIndex(function (variant) {
                        return forcedVariants.find(function (forcedVariant) {
                            return forcedVariant.userFriendlyVariantId ===
                                variant.userFriendlyId &&
                                forcedVariant.userFriendlyTestId ===
                                    (splitTestToRun === null || splitTestToRun === void 0 ? void 0 : splitTestToRun.test.userFriendlyId);
                        });
                    });
                    if (forcedVariantIndex !== -1) {
                        splitTestWeightedRandomVariant =
                            splitTest.test.variants[forcedVariantIndex];
                        previewOrForcedOrWinnerVariant = true;
                        log(logScope, "Selected forced variant:", splitTestWeightedRandomVariant);
                    }
                }
                else if (scriptDisabled) {
                    dontPushVariant = true;
                    log(logScope, "Skipping handling Split Tests because script is disabled.");
                    return { value: false };
                }
                else if (splitTest.test.winnerVariantFriendlyId) {
                    var winnerVariant = splitTest.test.variants.find(function (variant) {
                        return variant.userFriendlyId === splitTest.test.winnerVariantFriendlyId;
                    });
                    if (!winnerVariant) {
                        console.error("[Optibase] Error: Winner Variant with ID \"".concat(splitTest.test.winnerVariantFriendlyId, "\" does not exist."));
                        return { value: false };
                    }
                    dontPushVariant = true;
                    log(logScope, "Selected winner variant:", winnerVariant);
                    splitTestWeightedRandomVariant = winnerVariant;
                    previewOrForcedOrWinnerVariant = true;
                }
                else {
                    splitTestWeightedRandomVariant = selectRandomWeightedVariant(splitTest.test.variants, randomNumber);
                    log(logScope, "Selected random variant:", splitTestWeightedRandomVariant);
                }
            }
            if (!fromCached && !previewVariant && !dontPushVariant) {
                var currentPageSplitVariant = splitTest.test.variants.find(function (variant) { return doesUrlMatchCurrentOrigin(variant.url, sameOrigin); });
                if (currentPageSplitVariant) {
                    log(logScope, "Found a split test variant for the current page.");
                    shownVariants.push({
                        userFriendlyTestId: splitTest.test.userFriendlyId,
                        userFriendlyVariantId: currentPageSplitVariant.userFriendlyId,
                    });
                }
            }
        };
        for (var _i = 0, splitTests_1 = splitTests; _i < splitTests_1.length; _i++) {
            var splitTest = splitTests_1[_i];
            var state_1 = _loop_1(splitTest);
            if (typeof state_1 === "object")
                return state_1.value;
        }
        // Check if there's a split test to run for the current page
        if (splitTestToRun && splitTestWeightedRandomVariant) {
            log(logScope, "Found split test to run:", splitTestToRun);
            var shownBeforeVariant = splitTestToRun.test.variants.find(function (variant) { return variant.shownBefore; });
            var selectedVariant = previewOrForcedOrWinnerVariant
                ? splitTestWeightedRandomVariant
                : shownBeforeVariant || splitTestWeightedRandomVariant;
            log(logScope, "Selected variant:", selectedVariant);
            // Check if the selected variant is the current page
            // If the selected variant is the current page, log and keep the user on the same page
            if (selectedVariant.main) {
                log(logScope, "Not redirecting because selected variant is the current page.");
                return false;
            }
            else if (!previewVariant || previewOrForcedOrWinnerVariant) {
                if (!fromCached) {
                    log(logScope, "Redirecting to ".concat(selectedVariant.url, "."));
                    // If a different variant is selected, redirect the user to that variant's URL
                    var decodedURL = decodeURIComponent(selectedVariant.url);
                    window.location.href = decodedURL + window.location.search;
                }
                return true;
            }
            else {
                return true;
            }
        }
        else {
            log(logScope, "Found no split test to run.");
        }
        return false;
    }
    // Executes A/B tests by selectively showing or hiding elements based on the A/B test variants.
    // Elements associated with non-selected variants are either hidden or removed from the DOM.
    function handleABTests(abTests, fromCached, previewVariant, forcedVariants) {
        var logScope = fromCached ? 'handleABTests cached' : 'handleABTests';
        log(logScope, "Handling A/B Tests abTests:", abTests, ' fromCached:', fromCached);
        // Query the document for elements involved in A/B tests
        var elements = document.querySelectorAll('[data-optibase-variant-id], [data-optibase-test-id]');
        if (elements.length === 0) {
            log(logScope, "No elements with A/B testing attributes found.");
            return;
        }
        // Initialize groups to organize elements by their test ID
        var testVariantGroups = new Map();
        elements.forEach(function (el) {
            var userFriendlyVariantId = el.getAttribute('data-optibase-variant-id');
            var userFriendlyTestId = el.getAttribute('data-optibase-test-id');
            // Validate the presence of necessary attributes
            if (!userFriendlyTestId) {
                console.error("[Optibase] Error: Element on the site is missing 'data-optibase-test-id' attribute. Variant ID: ".concat(userFriendlyVariantId));
                return;
            }
            if (!userFriendlyVariantId) {
                console.error("[Optibase] Error: Element on the site missing 'data-optibase-variant-id' attribute. Test ID: ".concat(userFriendlyTestId));
                return;
            }
            var abTest = abTests === null || abTests === void 0 ? void 0 : abTests.find(function (abTest) { return abTest.test.userFriendlyId === userFriendlyTestId; });
            if (!abTest) {
                if (!fromCached) {
                    console.error("[Optibase] Error: Element on the site is not part of any test. Invalid Test ID: ".concat(userFriendlyTestId));
                }
                return;
            }
            if (!abTest.test.variants.find(function (variant) { return variant.userFriendlyId === userFriendlyVariantId; })) {
                if (fromCached) {
                    console.error("[Optibase] Error: Element on the site is not part of any test. Invalid Variant ID: ".concat(userFriendlyVariantId));
                }
                return;
            }
            // Group elements by their test ID
            if (!testVariantGroups.has(userFriendlyTestId)) {
                testVariantGroups.set(userFriendlyTestId, []);
            }
            testVariantGroups.get(userFriendlyTestId).push(el);
        });
        log(logScope, "Found elements for A/B Tests:", testVariantGroups);
        // Randomly select and display one variant from each group
        testVariantGroups.forEach(function (testVariantGroup, userFriendlyTestId) {
            var abTest = abTests === null || abTests === void 0 ? void 0 : abTests.find(function (abTest) { return abTest.test.userFriendlyId === userFriendlyTestId; });
            log(logScope, "Handling A/B test with userFriendlyId: ".concat(userFriendlyTestId, "."));
            if (abTest && !abTest.eligible) {
                log(logScope, "Test is not eligable bacause: ".concat(abTest.reason));
            }
            var selectedVariantElements = [];
            var mainVariant = abTest === null || abTest === void 0 ? void 0 : abTest.test.variants.find(function (variant) { return variant.main; });
            var mainVariantElements = testVariantGroup.filter(function (variant) {
                return (mainVariant === null || mainVariant === void 0 ? void 0 : mainVariant.userFriendlyId) &&
                    variant.getAttribute('data-optibase-variant-id') ===
                        mainVariant.userFriendlyId;
            });
            var forcedVariantsElements = testVariantGroup.filter(function (variant) {
                return forcedVariants.some(function (forcedVariant) {
                    return variant.getAttribute('data-optibase-variant-id') ===
                        forcedVariant.userFriendlyVariantId &&
                        userFriendlyTestId === forcedVariant.userFriendlyTestId;
                });
            });
            var previewVariantElements = testVariantGroup.filter(function (variant) {
                return ((previewVariant === null || previewVariant === void 0 ? void 0 : previewVariant.userFriendlyVariantId) &&
                    variant.getAttribute('data-optibase-variant-id') ===
                        previewVariant.userFriendlyVariantId &&
                    (abTest === null || abTest === void 0 ? void 0 : abTest.test.userFriendlyId) === (previewVariant === null || previewVariant === void 0 ? void 0 : previewVariant.userFriendlyTestId));
            });
            var winnerVariantElements = testVariantGroup.filter(function (variant) {
                return ((abTest === null || abTest === void 0 ? void 0 : abTest.test.winnerVariantFriendlyId) &&
                    variant.getAttribute('data-optibase-variant-id') ===
                        abTest.test.winnerVariantFriendlyId);
            });
            var dontPushVariant = false;
            if (previewVariantElements.length) {
                log(logScope, "Selecting the preview variant.");
                dontPushVariant = true;
                selectedVariantElements = previewVariantElements;
            }
            else if (forcedVariantsElements.length) {
                log(logScope, "Selecting the forced variant.");
                selectedVariantElements = forcedVariantsElements;
            }
            else if (scriptDisabled && mainVariantElements.length) {
                log(logScope, "Selecting the main variant by default because the script is disabled.");
                dontPushVariant = true;
                selectedVariantElements = mainVariantElements;
            }
            else if (winnerVariantElements.length) {
                log(logScope, "Selecting the winner variant.");
                dontPushVariant = true;
                selectedVariantElements = winnerVariantElements;
            }
            else if (!(abTest === null || abTest === void 0 ? void 0 : abTest.eligible) && mainVariantElements.length) {
                log(logScope, "Selecting the main variant because test is not eligible.");
                dontPushVariant = true;
                selectedVariantElements = mainVariantElements;
            }
            else {
                var shownBeforeVariant_1 = abTest === null || abTest === void 0 ? void 0 : abTest.test.variants.find(function (variant) { return variant.shownBefore; });
                var shownBeforeVariantElements = testVariantGroup.filter(function (variant) {
                    return variant.getAttribute('data-optibase-variant-id') ===
                        (shownBeforeVariant_1 === null || shownBeforeVariant_1 === void 0 ? void 0 : shownBeforeVariant_1.userFriendlyId);
                });
                if (shownBeforeVariantElements.length) {
                    selectedVariantElements = shownBeforeVariantElements;
                }
                else {
                    var randomNumber = generateSeededRandomNumber();
                    var userFriendlyVariantIdsWithWeights = testVariantGroup.map(function (element) {
                        var userFriendlyVariantId = element.getAttribute('data-optibase-variant-id');
                        var variant = abTest === null || abTest === void 0 ? void 0 : abTest.test.variants.find(function (variant) { return variant.userFriendlyId === userFriendlyVariantId; });
                        return {
                            userFriendlyVariantId: userFriendlyVariantId,
                            trafficSplitWeight: (variant === null || variant === void 0 ? void 0 : variant.trafficSplitWeight) || 0,
                        };
                    });
                    var randomUserFriendlyVariantId_1 = selectRandomWeightedVariant(userFriendlyVariantIdsWithWeights, randomNumber).userFriendlyVariantId;
                    selectedVariantElements = testVariantGroup.filter(function (variant) {
                        return variant.getAttribute('data-optibase-variant-id') ===
                            randomUserFriendlyVariantId_1;
                    });
                }
            }
            var userFriendlyVariantId = selectedVariantElements[0].getAttribute('data-optibase-variant-id');
            log(logScope, "Selected variant with userFriendlyVariantId: ".concat(userFriendlyVariantId, ". Elements:"), selectedVariantElements);
            if (!previewVariant &&
                !scriptDisabled &&
                (abTest === null || abTest === void 0 ? void 0 : abTest.eligible) &&
                !dontPushVariant &&
                !fromCached) {
                shownVariants.push({
                    userFriendlyTestId: userFriendlyTestId,
                    userFriendlyVariantId: userFriendlyVariantId,
                });
            }
            // Make them visible again
            for (var _i = 0, selectedVariantElements_1 = selectedVariantElements; _i < selectedVariantElements_1.length; _i++) {
                var selectedVariantElement = selectedVariantElements_1[_i];
                if (selectedVariantElement.style.display === 'none') {
                    selectedVariantElement.style.display = '';
                }
            }
            for (var _a = 0, testVariantGroup_1 = testVariantGroup; _a < testVariantGroup_1.length; _a++) {
                var element = testVariantGroup_1[_a];
                var elementUserFriendlyVariantId = element.getAttribute('data-optibase-variant-id');
                if (elementUserFriendlyVariantId !== userFriendlyVariantId) {
                    if (fromCached) {
                        element.style.display = 'none';
                    }
                    else {
                        element.remove();
                    }
                }
            }
        });
    }
    /**
     * Sends an event to the Optibase API for tracking conversions.
     */
    function sendConversionEvent(userFriendlyConversionId) {
        return __awaiter(this, void 0, void 0, function () {
            var conversionEventBody, response, responseJson_2, responseText, responseJson, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!publicApiKey) {
                            throw new Error('[Optibase] publicApiKey is undefined');
                        }
                        if (!testedUserId) {
                            throw new Error('[Optibase] testedUserId is undefined');
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 7, , 8]);
                        conversionEventBody = {
                            publicApiKey: publicApiKey,
                            testedUserId: testedUserId,
                            userFriendlyConversionId: userFriendlyConversionId,
                            originUrl: currentOriginUrl,
                            samePageVariants: shownVariants,
                        };
                        log('sendConversionEvent', "Calling with body:", conversionEventBody);
                        return [4 /*yield*/, fetch("".concat(baseApiUrl, "/api/script/conversionEvent"), {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(conversionEventBody),
                            }).catch(function (error) {
                                // Don't surface the error to Sentry because
                                // it's usually a network error
                                console.error("[Optibase] Error occurred while fetching conversionEvent. Details:", error);
                            })];
                    case 2:
                        response = _a.sent();
                        if (!response) {
                            return [2 /*return*/];
                        }
                        if (!!response.ok) return [3 /*break*/, 5];
                        return [4 /*yield*/, response.json().catch(function () { })];
                    case 3:
                        responseJson_2 = _a.sent();
                        return [4 /*yield*/, response.text().catch(function () { })];
                    case 4:
                        responseText = _a.sent();
                        if (response.status === 400 && !responseJson_2.clientError) {
                            // This means it was a user error, like running on
                            // a non valid domain
                            throw new OptibaseUserError('[Optibase] Response not ok when calling conversionEvent: ' +
                                (responseJson_2 === null || responseJson_2 === void 0 ? void 0 : responseJson_2.message) ||
                                responseText ||
                                'Unknown error');
                        }
                        else if (response.status === 500) {
                            // This means it was an error on the server, don't surface to Sentry
                            throw new OptibaseUserError('[Optibase] Response not ok when calling conversionEvent: ' +
                                (responseJson_2 === null || responseJson_2 === void 0 ? void 0 : responseJson_2.message) ||
                                responseText ||
                                'Internal Server Error');
                        }
                        else {
                            throw new Error('[Optibase] Response not ok when calling conversionEvent: ' +
                                (responseJson_2 === null || responseJson_2 === void 0 ? void 0 : responseJson_2.message) ||
                                responseText ||
                                'Unknown error');
                        }
                        _a.label = 5;
                    case 5: return [4 /*yield*/, response.json()];
                    case 6:
                        responseJson = _a.sent();
                        log('sendConversionEvent', "Response: ", responseJson);
                        return [3 /*break*/, 8];
                    case 7:
                        error_1 = _a.sent();
                        if (!(error_1 instanceof OptibaseUserError)) {
                            optibaseSentryClient === null || optibaseSentryClient === void 0 ? void 0 : optibaseSentryClient.captureException(error_1);
                        }
                        console.error("[Optibase] Error occurred while sending a conversion event. Details:", error_1);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    }
    function isGA4Installed() {
        // @ts-ignore
        return typeof window.gtag === 'function';
    }
    // Send variant information to GA4
    function sendVariantToGA4(shownVariant) {
        log('sendVariantToGA4', "Sending variant ".concat(shownVariant.userFriendlyTestId +
            ':' +
            shownVariant.userFriendlyVariantId, " to GA4."));
        // @ts-ignore
        window.gtag('event', 'optibase_variant_shown', {
            event_category: 'optibase',
            event_label: shownVariant.userFriendlyTestId +
                ':' +
                shownVariant.userFriendlyVariantId,
        });
    }
    // Function to check if Mixpanel is installed
    function isMixpanelInstalled() {
        return (
        // @ts-ignore
        typeof mixpanel !== 'undefined' && typeof mixpanel.track === 'function');
    }
    // Send variant information to Mixpanel
    // where name of the event property is optibase_userFriendlyTestId
    // and value of the event property is optibase_userFriendlyVariantId
    function sendVariantToMixpanel(shownVariant) {
        log('sendVariantToMixpanel', "Sending variant ".concat(shownVariant.userFriendlyTestId +
            ':' +
            shownVariant.userFriendlyVariantId, " to Mixpanel."));
        // @ts-ignore
        mixpanel.track('optibase_variant_shown', {
            testId: shownVariant.userFriendlyTestId,
            variantId: shownVariant.userFriendlyTestId +
                ':' +
                shownVariant.userFriendlyVariantId,
        });
    }
    /**
     * Sends an View to the Optibase API.
     */
    function sendView(timeToSetup) {
        return __awaiter(this, void 0, void 0, function () {
            var viewBody, response, responseJson_3, responseText, responseJson, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (shownVariants.length === 0) {
                            log('sendView', "No variants to send. Time to setup: ".concat(timeToSetup, "ms."));
                            return [2 /*return*/];
                        }
                        if (!publicApiKey) {
                            throw new Error('[Optibase] publicApiKey is undefined');
                        }
                        if (!testedUserId) {
                            throw new Error('[Optibase] testedUserId is undefined');
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 7, , 8]);
                        viewBody = {
                            publicApiKey: publicApiKey,
                            testedUserId: testedUserId,
                            variants: shownVariants,
                            originUrl: currentOriginUrl,
                            timeToSetup: timeToSetup,
                        };
                        window.optibaseActiveVariants = shownVariants;
                        optibaseActiveVariantsListeners.forEach(function (listener) {
                            return listener(shownVariants);
                        });
                        // Send events to GA4
                        if (isGA4Installed()) {
                            shownVariants.forEach(function (shownVariant) {
                                sendVariantToGA4(shownVariant);
                            });
                        }
                        else {
                            log('sendVariantToGA4', 'Google Analytics (GA4) is not installed.');
                        }
                        // Send events to Mixpanel
                        if (isMixpanelInstalled()) {
                            shownVariants.forEach(function (shownVariant) {
                                sendVariantToMixpanel(shownVariant);
                            });
                        }
                        else {
                            log('sendVariantToMixpanel', 'Mixpanel is not installed.');
                        }
                        log('sendView', "Calling with body:", viewBody);
                        return [4 /*yield*/, fetch("".concat(baseApiUrl, "/api/script/view"), {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(viewBody),
                            }).catch(function (error) {
                                // Don't surface the error to Sentry because
                                // it's usually a network error
                                console.error("[Optibase] Error occurred while fetching view. Details:", error);
                            })];
                    case 2:
                        response = _a.sent();
                        if (!response) {
                            return [2 /*return*/];
                        }
                        if (!!response.ok) return [3 /*break*/, 5];
                        return [4 /*yield*/, response.json().catch(function () { })];
                    case 3:
                        responseJson_3 = _a.sent();
                        return [4 /*yield*/, response.text().catch(function () { })];
                    case 4:
                        responseText = _a.sent();
                        if (response.status === 400 && !responseJson_3.clientError) {
                            // This means it was a user error, like running on
                            // a non valid domain
                            throw new OptibaseUserError('[Optibase] Response not ok when calling view: ' +
                                (responseJson_3 === null || responseJson_3 === void 0 ? void 0 : responseJson_3.message) ||
                                responseText ||
                                'Unknown error');
                        }
                        else if (response.status === 500) {
                            // This means it was an error on the server, don't surface to Sentry
                            throw new OptibaseUserError('[Optibase] Response not ok when calling view: ' +
                                (responseJson_3 === null || responseJson_3 === void 0 ? void 0 : responseJson_3.message) ||
                                responseText ||
                                'Internal Server Error');
                        }
                        else {
                            throw new Error('[Optibase] Response not ok when calling view: ' +
                                (responseJson_3 === null || responseJson_3 === void 0 ? void 0 : responseJson_3.message) ||
                                responseText ||
                                'Unknown error');
                        }
                        _a.label = 5;
                    case 5: return [4 /*yield*/, response.json()];
                    case 6:
                        responseJson = _a.sent();
                        log('sendView', "Response:", responseJson);
                        return [3 /*break*/, 8];
                    case 7:
                        error_2 = _a.sent();
                        if (!(error_2 instanceof OptibaseUserError)) {
                            optibaseSentryClient === null || optibaseSentryClient === void 0 ? void 0 : optibaseSentryClient.captureException(error_2);
                        }
                        console.error("[Optibase] Error occurred while sending a View. Details:", error_2);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    }
    function handleClickConversions(clickConversions) {
        log('handleClickConversions', "Setting up click listeners.");
        // Select all elements with a click conversion ID attribute
        var clickableElements = document.querySelectorAll('[data-optibase-click-conversion-id]');
        if (clickableElements.length === 0) {
            log('handleClickConversions', "No elements with click conversion attribute found.");
            return;
        }
        log('handleClickConversions', "Found elements with click conversion attribute:", clickableElements);
        // Attach click event listeners to each found element
        clickableElements.forEach(function (element) {
            var clickConversionId = element.getAttribute('data-optibase-click-conversion-id');
            if (!clickConversions.find(function (conversion) { return conversion.userFriendlyId === clickConversionId; })) {
                console.error("[Optibase] Error: Element with click conversion ID \"".concat(clickConversionId, "\" does not have a corresponding Click Conversion on the server."));
                return;
            }
            element.addEventListener('click', function (event) {
                return __awaiter(this, void 0, void 0, function () {
                    var anchorElement, url;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(element.tagName === 'A')) return [3 /*break*/, 2];
                                event.preventDefault();
                                return [4 /*yield*/, sendConversionEvent(clickConversionId)];
                            case 1:
                                _a.sent();
                                anchorElement = element;
                                url = anchorElement.href // Get the href value from the <a> element
                                ;
                                if (url) {
                                    if (anchorElement.target === '_blank') {
                                        // If the target is '_blank', open the URL in a new tab
                                        window.open(url, '_blank');
                                    }
                                    else {
                                        // Otherwise, navigate in the current tab/window
                                        window.location.href = url;
                                    }
                                }
                                return [3 /*break*/, 3];
                            case 2:
                                sendConversionEvent(clickConversionId);
                                _a.label = 3;
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            });
        });
    }
    function handleSubmitConversions(submitConversions) {
        log('handleSubmitConversions', "Setting up submit listeners.");
        // Select all form elements with a submit conversion ID attribute
        var submitableElements = document.querySelectorAll('[data-optibase-submit-conversion-id]');
        if (submitableElements.length === 0) {
            log('handleSubmitConversions', "No elements with submit conversion attribute found.");
            return;
        }
        log('handleSubmitConversions', "Found elements with submit conversion attribute:", submitableElements);
        // Attach submit event listeners to each found form element
        submitableElements.forEach(function (element) {
            var submitConversionId = element.getAttribute('data-optibase-submit-conversion-id');
            if (!submitConversions.find(function (conversion) { return conversion.userFriendlyId === submitConversionId; })) {
                console.error("[Optibase] Error: Element with submit conversion ID \"".concat(submitConversionId, "\" does not have a corresponding Submit Conversion on the server."));
                return;
            }
            function handleSubmitEvent(event) {
                return __awaiter(this, void 0, void 0, function () {
                    var formElement;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                log('handleSubmitConversions', "Handling submit event for conversion ID \"".concat(submitConversionId, "\"."));
                                if (!(element.getAttribute('action') && !event.defaultPrevented)) return [3 /*break*/, 2];
                                event.preventDefault();
                                return [4 /*yield*/, sendConversionEvent(submitConversionId)];
                            case 1:
                                _a.sent();
                                formElement = element;
                                formElement.submit();
                                return [3 /*break*/, 3];
                            case 2:
                                sendConversionEvent(submitConversionId);
                                _a.label = 3;
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            }
            // If the element is not a form, it's probably an embed
            // and we need to wait for the form to be added to the DOM
            if (element.tagName !== 'FORM') {
                log('handleSubmitConversions', "Element with submit conversion ID \"".concat(submitConversionId, "\" is not a form element. Waiting for form to be added to the DOM by adding an observer."));
                var lookForForm_1 = function () {
                    // Handle elements that are divs that include an iframe that include a form element
                    var form = element.querySelector('form');
                    if (!form) {
                        var iframe = element.querySelector('iframe');
                        if (!iframe) {
                            log('handleSubmitConversions', "Element with submit conversion ID \"".concat(submitConversionId, "\" is not a form element, does not contain a form element, and is not an iframe."));
                            return;
                        }
                        var iframeDocument = iframe.contentDocument;
                        if (!iframeDocument) {
                            log('handleSubmitConversions', "[Optibase] iframe in element with submit conversion ID \"".concat(submitConversionId, "\" does not have a contentDocument."));
                            return;
                        }
                        form = iframeDocument.querySelector('form');
                        if (!form) {
                            log('handleSubmitConversions', "[Optibase] iframe in element with submit conversion ID \"".concat(submitConversionId, "\" does not have a form element."));
                            return;
                        }
                    }
                    log('handleSubmitConversions', "Adding submit listener to the found form element for submit conversion ID \"".concat(submitConversionId, "\":"), form);
                    observer_1.disconnect();
                    form.addEventListener('submit', handleSubmitEvent);
                };
                var observer_1 = new MutationObserver(function (mutations) {
                    log('handleSubmitConversions MutationObserver', "Found Mutations for submit conversion ID \"".concat(submitConversionId, "\":"), mutations);
                    mutations.forEach(function (mutation) {
                        if (mutation.type == 'childList') {
                            lookForForm_1();
                        }
                    });
                });
                var config = {
                    childList: true,
                    subtree: true, // Add this line to observe changes in descendants
                };
                observer_1.observe(element, config);
                lookForForm_1();
            }
            else {
                log('handleSubmitConversions', "Adding submit listener to the found form element for submit conversion ID \"".concat(submitConversionId, "\":"), element);
                var form = element;
                form.addEventListener('submit', handleSubmitEvent);
            }
        });
    }
    function handlePageViewConversion(pageViewConversions) {
        var _this = this;
        log('handlePageViewConversion', "Handling page view conversions.");
        if (pageViewConversions.length === 0) {
            log('handlePageViewConversion', "No page view conversions found.");
            return;
        }
        log('handlePageViewConversion', "Found page view conversions:", pageViewConversions);
        pageViewConversions.forEach(function (pageViewConversion) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!doesUrlMatchCurrentOrigin(pageViewConversion.url, pageViewConversion.sameOrigin)) return [3 /*break*/, 2];
                        log('handlePageViewConversion', "Sending page view conversion with userFriendlyId: ".concat(pageViewConversion.userFriendlyId, "."));
                        return [4 /*yield*/, sendConversionEvent(pageViewConversion.userFriendlyId)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); });
    }
    function handleProgrammaticConversion(userFriendlyConversionId) {
        return __awaiter(this, void 0, void 0, function () {
            var error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        log('handleProgrammaticConversion', "Handling programmatic conversion with userFriendlyConversionId: ".concat(userFriendlyConversionId, "."));
                        if (!window.optibaseInitialized) {
                            console.error('[Optibase] Did not send conversion event: Optibase did not fully initialize');
                            return [2 /*return*/];
                        }
                        if (window.monthlyTestedUsersLimitReached) {
                            console.error('[Optibase] Did not send conversion event: Monthly Tested Users limit has been reached.');
                            return [2 /*return*/];
                        }
                        if (window.noTestsOrConversionsOnPage) {
                            console.error('[Optibase] Did not send conversion event: No tests or conversions found on the page. The conversion needs to be programmatic.');
                            return [2 /*return*/];
                        }
                        if (window.userNotLoggedIn30Days) {
                            console.error('[Optibase] Did not send conversion event: The user has not logged in in the last 30 days.');
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, sendConversionEvent(userFriendlyConversionId)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_3 = _a.sent();
                        console.error("[Optibase] Error occurred while sending a conversion event. Details:", error_3);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    window.optibaseSendConversionEvent = handleProgrammaticConversion;
    function getScrollDepth() {
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        var documentHeight = document.documentElement.scrollHeight;
        var windowHeight = window.innerHeight;
        var scrollDepth = scrollTop + windowHeight;
        return (scrollDepth / documentHeight) * 100; // returns scroll depth as a percentage of total document height
    }
    function calculateTriggerDepth(conversion) {
        var documentHeight = document.documentElement.scrollHeight;
        if (conversion.scrollDepthUnit === ScrollDepthUnit.PERCENTAGE) {
            return conversion.scrollDepthValue; // Already in percentage
        }
        else if (conversion.scrollDepthUnit === ScrollDepthUnit.PIXELS) {
            return (conversion.scrollDepthValue / documentHeight) * 100; // Convert pixels to percentage
        }
        return Infinity; // Default to a very high value if no valid unit is found, effectively disabling the conversion
    }
    function handleScrollDepthConversions(scrollConversions) {
        log('handleScrollConversions', "Setting up scroll listeners.");
        var eligableScrollConversions = scrollConversions.filter(function (conversion) {
            return doesUrlMatchCurrentOrigin(conversion.url, conversion.sameOrigin);
        });
        if (eligableScrollConversions.length === 0) {
            log('handleScrollConversions', "No scroll depth conversions found.");
            return;
        }
        log('handleScrollConversions', "Found scroll depth conversions:", eligableScrollConversions);
        // Ensure conversions are only triggered once
        var triggeredConversions = new Set();
        window.addEventListener('scroll', function () {
            var scrollDepth = getScrollDepth();
            eligableScrollConversions.forEach(function (conversion) {
                if (!triggeredConversions.has(conversion.userFriendlyId)) {
                    var triggerDepth = calculateTriggerDepth(conversion);
                    if (scrollDepth >= triggerDepth) {
                        log('Scroll Depth Conversion Triggered', "Conversion ID: ".concat(conversion.userFriendlyId));
                        sendConversionEvent(conversion.userFriendlyId);
                        triggeredConversions.add(conversion.userFriendlyId);
                    }
                }
            });
        }, { passive: true });
    }
    function handleTimeOnPageConversions(timeOnPageConversions) {
        log('handleTimeOnPageConversions', "Setting up time on page listeners.");
        var eligableTimeOnPageConversions = timeOnPageConversions.filter(function (conversion) {
            return doesUrlMatchCurrentOrigin(conversion.url, conversion.sameOrigin);
        });
        if (eligableTimeOnPageConversions.length === 0) {
            log('handleTimeOnPageConversions', "No time on page conversions found.");
            return;
        }
        log('handleTimeOnPageConversions', "Found time on page conversions:", eligableTimeOnPageConversions);
        var _loop_2 = function (conversion) {
            setTimeout(function () {
                log('Time On Page Conversion Triggered', "Conversion ID: ".concat(conversion.userFriendlyId));
                sendConversionEvent(conversion.userFriendlyId);
            }, conversion.timeOnPage * 1000);
        };
        for (var _i = 0, eligableTimeOnPageConversions_1 = eligableTimeOnPageConversions; _i < eligableTimeOnPageConversions_1.length; _i++) {
            var conversion = eligableTimeOnPageConversions_1[_i];
            _loop_2(conversion);
        }
    }
    function setTestedUserExternalId(externalId) {
        return __awaiter(this, void 0, void 0, function () {
            var setTestedUserExternalIdBody, response, responseJson_4, responseText, responseJson;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        log('setTestedUserExternalId', "Setting tested user external ID:", externalId);
                        if (window.monthlyTestedUsersLimitReached) {
                            console.error('[Optibase] Did not set Tested User External ID: Monthly Tested Users limit has been reached.');
                            return [2 /*return*/];
                        }
                        if (window.userNotLoggedIn30Days) {
                            console.error('[Optibase] Did not send conversion event: The user has not logged in in the last 30 days.');
                            return [2 /*return*/];
                        }
                        if (!publicApiKey) {
                            console.error('[Optibase] Did not set Tested User External ID: Public API Key is not set.');
                            return [2 /*return*/];
                        }
                        if (!testedUserId) {
                            console.error("[Optibase] Did not set Tested User External ID: Tested User ID is not set. This user hasn't encountered any tests yet.");
                            return [2 /*return*/];
                        }
                        if (!window.optibaseInitialized) {
                            console.error('[Optibase] Did not set Tested User External ID: Optibase did not fully initialized.');
                            return [2 /*return*/];
                        }
                        setTestedUserExternalIdBody = {
                            publicApiKey: publicApiKey,
                            testedUserId: testedUserId,
                            testedUserExternalId: externalId,
                        };
                        log('setTestedUserExternalId', "Calling with body:", setTestedUserExternalIdBody);
                        return [4 /*yield*/, fetch("".concat(baseApiUrl, "/api/script/setTestedUserExternalId"), {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(setTestedUserExternalIdBody),
                            })];
                    case 1:
                        response = _a.sent();
                        if (!response) {
                            throw new Error('[Optibase] Response not ok when calling setTestedUserExternalId: No response.');
                        }
                        if (!!response.ok) return [3 /*break*/, 4];
                        return [4 /*yield*/, response.json().catch(function () { })];
                    case 2:
                        responseJson_4 = _a.sent();
                        return [4 /*yield*/, response.text().catch(function () { })];
                    case 3:
                        responseText = _a.sent();
                        if (response.status === 400 && !responseJson_4.clientError) {
                            // This means it was a user error, like running on
                            // a non valid domain
                            throw new OptibaseUserError('[Optibase] Response not ok when calling setTestedUserExternalId: ' +
                                (responseJson_4 === null || responseJson_4 === void 0 ? void 0 : responseJson_4.message) ||
                                responseText ||
                                'Unknown error');
                        }
                        else if (response.status === 500) {
                            // This means it was an error on the server, don't surface to Sentry
                            throw new OptibaseUserError('[Optibase] Response not ok when calling setTestedUserExternalId: ' +
                                (responseJson_4 === null || responseJson_4 === void 0 ? void 0 : responseJson_4.message) ||
                                responseText ||
                                'Internal Server Error');
                        }
                        else {
                            throw new Error('[Optibase] Response not ok when calling setTestedUserExternalId: ' +
                                (responseJson_4 === null || responseJson_4 === void 0 ? void 0 : responseJson_4.message) ||
                                responseText ||
                                'Unknown error');
                        }
                        _a.label = 4;
                    case 4: return [4 /*yield*/, response.json()];
                    case 5:
                        responseJson = _a.sent();
                        testedUserExternalId = externalId;
                        window.optibaseTestedUserExternalId = externalId;
                        localStorage === null || localStorage === void 0 ? void 0 : localStorage.setItem('optibaseTestedUserExternalId', externalId);
                        log('setTestedUserExternalId', "Response:", responseJson);
                        return [2 /*return*/];
                }
            });
        });
    }
    window.optibaseSetTestedUserExternalId = setTestedUserExternalId;
    var optibaseActiveVariantsListeners = [];
    function optibaseAddActiveVariantsListener(callback) {
        optibaseActiveVariantsListeners.push(callback);
        if (window.optibaseActiveVariants) {
            callback(window.optibaseActiveVariants);
        }
    }
    window.optibaseAddActiveVariantsListener = optibaseAddActiveVariantsListener;
    /* ---------- Execution Starts Here --------- */
    // Local storage can sometimes be blocked and throw an error:
    // Failed to read the 'localStorage' property from 'Window': Access is denied for this document.
    var localStorage = null;
    try {
        localStorage = window.localStorage;
        localStorage.getItem('optibase');
    }
    catch (_a) {
        localStorage = null;
    }
    // Used to detect if script has already been loaded before.
    window.optibaseScriptLoaded = true;
    var startDate = null;
    var debugMode = false;
    var _b = getConfig(), scriptDisabled = _b.scriptDisabled, previewVariant = _b.previewVariant, forcedVariants = _b.forcedVariants;
    if (/bot|google|baidu|bing|msn|teoma|slurp|yandex|Webflow/i.test(navigator.userAgent)) {
        log('botDetection', "Detected bot user agent.");
        // If the user agent is a bot, disable the script.
        scriptDisabled = true;
    }
    var _c = validateExecution(), publicApiKey = _c.publicApiKey, baseApiUrl = _c.baseApiUrl, canHideBody = _c.canHideBody;
    // Metadata Variables - Are final and will not be modified by the script.
    var currentBrowser = getCurrentBrowser();
    var currentOS = getCurrentOS();
    var currentScreenSize = getCurrentScreenSize();
    var currentTopReferrer = getCurrentTopReferrer();
    Sentry.setTag('browser', currentBrowser);
    Sentry.setTag('os', currentOS);
    var currentOriginUrl = getCurrentOriginUrl();
    var cleanCurrentOriginUrl = validateAndCleanUrl(currentOriginUrl);
    var cleanCurrentOriginPath = validateAndExtractPathFromUrl(currentOriginUrl);
    // Dynamic Variables - Are not final and might be modified by the script.
    var testedUserId = localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem('optibaseTestedUserId');
    window.optibaseTestedUserId = testedUserId;
    var testedUserExternalId = localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem('optibaseTestedUserExternalId');
    window.optibaseTestedUserExternalId = testedUserExternalId;
    var shownVariants = [];
    if (canHideBody) {
        hideBody();
        // Show body no matter what after 5 seconds
        setTimeout(function () {
            showBody();
        }, 5000);
    }
    // Initializes the testing framework once the DOM content is fully loaded.
    // This function fetches test configurations from the server, processes split and A/B tests,
    // and sets up event listeners for conversion tracking. It also handles visibility of the body
    // element to prevent flickering during test variant application.
    function onDOMContentLoaded() {
        return __awaiter(this, void 0, void 0, function () {
            var variantsOnPage, clickConversionsOnPage, submitConversionsOnPage, initializeResponse, error_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        log('onDOMContentLoaded', 'DOM Content Loaded');
                        startDate = new Date();
                        handleCachedInitializeResponse((localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem('optibaseCachedInitializeResponse')) || null);
                        variantsOnPage = document.querySelectorAll('[data-optibase-variant-id]');
                        clickConversionsOnPage = document.querySelectorAll('[data-optibase-click-conversion-id]');
                        submitConversionsOnPage = document.querySelectorAll('[data-optibase-submit-conversion-id]');
                        if (!Boolean(variantsOnPage.length)) {
                            log('onDOMContentLoaded', "No variants found on the page.");
                        }
                        if (!Boolean(clickConversionsOnPage.length)) {
                            log('onDOMContentLoaded', "No click conversions found on the page.");
                        }
                        if (!Boolean(submitConversionsOnPage.length)) {
                            log('onDOMContentLoaded', "No submit conversions found on the page.");
                        }
                        return [4 /*yield*/, getInitialize(Boolean(variantsOnPage.length), Boolean(clickConversionsOnPage.length) ||
                                Boolean(submitConversionsOnPage.length))];
                    case 1:
                        initializeResponse = _a.sent();
                        log('getInitialize', "Received response");
                        return [4 /*yield*/, handleInitializeResponse(initializeResponse)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_4 = _a.sent();
                        if (!(error_4 instanceof OptibaseUserError)) {
                            optibaseSentryClient === null || optibaseSentryClient === void 0 ? void 0 : optibaseSentryClient.captureException(error_4);
                        }
                        console.error('[Optibase] Encountered an error:', error_4);
                        // Make sure, even if something goes wrong, to show the page at the end.
                        window.optibaseInitialized = true;
                        showBody();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    if (document.readyState !== 'loading') {
        // DOM content already loaded
        onDOMContentLoaded();
    }
    else {
        document.addEventListener('DOMContentLoaded', onDOMContentLoaded);
    }
}
try {
    Optibase();
}
catch (error) {
    if (!(error instanceof OptibaseUserError)) {
        optibaseSentryClient === null || optibaseSentryClient === void 0 ? void 0 : optibaseSentryClient.captureException(error);
    }
    throw error;
}
